import { Setting } from './../../../features/admin/setting/models/setting.model';
import { Injectable } from '@angular/core';
import { ApiHttpService } from '@capturum/api';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@capturum/auth';
import {
  CompleteConfig,
  IndexedDbGuard,
  IndexedDbService,
  SettingApiModel,
  SettingIndexedDbModel,
} from '@capturum/complete';
import { Observable, of, Subject } from 'rxjs';
import { NavigationService } from '@core/services/navigation.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { IndexableDataService } from '@core/indexDb/services/dxp-indexable-data.service';
import { CacheService } from '@core/services/cache.service';

@Injectable({
  providedIn: 'root',
})
export class DxpIndexableSettingService extends IndexableDataService<SettingApiModel> {
  public settingsChanged$: Subject<void> = new Subject();
  protected endpoint = 'setting';
  protected outdatedEntityKey = 'settings';

  constructor(
    protected readonly api: ApiHttpService,
    protected readonly translateService: TranslateService,
    protected readonly authService: AuthService,
    protected readonly cacheService: CacheService,
    protected readonly navigationService: NavigationService,
    protected readonly completeConfig: CompleteConfig,
    protected readonly indexedDbService: IndexedDbService,
    protected readonly indexedDbGuard: IndexedDbGuard,
  ) {
    super(api, translateService, authService, completeConfig, indexedDbService, indexedDbGuard);

    this.defaultApiOptions = {
      perPage: 100,
    };
  }

  /**
   * I would deprecate this method, but @Emendis/complete components rely on it
   */
  public loadAll(forceReload = false): Observable<any> {
    return this.getData(forceReload).pipe(
      tap(() => {
        this.setGlobalStyles();
        this.settingsChanged$.next();
      }),
    );
  }

  /**
   * Set global styles
   */
  public setGlobalStyles(): void {
    const colorSettings = SettingIndexedDbModel.settings.filter((setting) => {
      return (setting.id as string).includes('color');
    });

    for (const setting of colorSettings) {
      let key = setting.id as string;

      if (key.includes('general.tenant_')) {
        key = key.split('general.tenant_')[1];
      }

      document.documentElement.style.setProperty(
        `--${key
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .replace(/[\s_]+/g, '-')
          .toLowerCase()}`,
        setting.value as string,
      );
    }
  }

  /**
   * Set a setting for a tenant
   */
  public set(settingId: string, data: { value: any; tenant_id?: string }): Observable<any> {
    if (typeof data.value !== 'string') {
      data.value = JSON.stringify(data.value);
    }

    return this.apiHttp.post(`/setting/set/${settingId}`, data).pipe(
      switchMap(() => {
        return this.getData(true);
      }),
      switchMap((response) => {
        return this.setIndexedData(response);
      }),
      tap(() => {
        this.setGlobalStyles();
        this.settingsChanged$.next();
      }),
    );
  }

  public onTransformRequestedData(data: any[]): Observable<any> {
    const transformedSettings = [];

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        transformedSettings.push({ id: key, value: data[key] });
      }
    }

    return of(transformedSettings);
  }

  public onFatalError(): Observable<void> {
    return super.onFatalError().pipe(
      tap(() => {
        this.cacheService.forceLogout();
      }),
    );
  }

  public getSettingByKey(key: string, forceNetworkRequest = false): Observable<Setting> {
    return this.getData(forceNetworkRequest).pipe(
      map((settings: { id: string; value: Setting }[]) => {
        return settings?.find((setting) => {
          return setting?.value?.name === key;
        })?.value;
      }),
    );
  }
}
